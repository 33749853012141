import React, { useEffect, useState } from 'react';
import { Button, Container, Input, CheckBox } from '../../components';
import { Formik } from 'formik';

import { Forms, CheckBoxContainer } from './styles';
import api from '../../services/api';

const AreaCreation = () => {
  const [members, setMembers] = useState([]);


  useEffect(() => {
    async function fetchData() {
      const answer = await api.get('/dev');
      console.log(answer.data.members);
      setMembers(answer.data.members);
      
    }
    fetchData();
  }, []);

  const initialValues={
    areaName:"",
    checked:[]
  }

  const createArea= async (values, resetForm)=>{
    const {areaName, checked} = values;
    
    const answer = await api.post(`/dev?areaname=${areaName}&members=${checked}&activity=new_area`);
        console.log(answer)
    if(answer.status === 200){
     resetForm();     
    }
  }

  return (
    <Container>
      <Formik
      initialValues={initialValues}
      onSubmit={(values,{resetForm})=>createArea(values, resetForm)}>
      <Forms>
        <Input name="areaName"label="Nome da área" placeholder="GVCode" />
        <h2>Escolher membros</h2>
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          {members.map((name, index) => {
            return (
              <CheckBoxContainer>
                <CheckBox
                  name="checked"
                  value={`${name[0]}`}
                  label={name[1]}
                />
              </CheckBoxContainer>
            );
          })}
        </div>

        <Button type="submit">Adicionar Área</Button>
      </Forms>
      </Formik>
      
    </Container>
  );
};

export default AreaCreation;
