import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';


import { Button, Container, Input } from '../../components';

import { Forms, UserContainer } from './styles';
import api from '../../services/api';

const UserCreation = () => {
  const [loading, setLoading] =useState(false);
  const [members, setMembers] = useState([]);
  const [addedMember, setAddedMember] =useState(false);

  useEffect(() => {
    async function fetchData() {
      const answer = await api.get('/dev');
      setMembers(answer.data.members);
    }
    fetchData();
  }, [addedMember]);


  const createUser = async (values, resetForm) => {
    const {name} = values;
    setLoading(true);
    
    const answer = await api.post(`/dev?username=${name}&activity=new_member`);
    
    setLoading(false);
    
    if(answer.status === 200){
      resetForm();
      setAddedMember(true);
      setTimeout(function(){setAddedMember(false)}, 3000);
    }
  };
  
  const initialValues = {
    name: '',
  };

  return (
    <>
      <Container>
        <Formik
          initialValues={initialValues}
          onSubmit={(values,{resetForm})=>createUser(values, resetForm)}
        >
          <Forms>
            <Input
              name="name"
              label="Nome"
              placeholder="Giovanna Fernandes"
            />
            
            <Button 
              type="submit"
              disabled={loading} 
              variant={loading?"disabled": "default"}
            >
              {loading?
                <p>Carregando...</p>:
                <p>Adicionar Membro</p>
              }
              </Button>
              {addedMember? <p>Membro adicionado!</p>: <></>}
          
          </Forms>
        </Formik>
      </Container>
      <Container style={{display:'flex', flexDirection: 'column', alignItems: 'center'}}>
        {members.slice(0).reverse().map((member, index) => {
            return (
              <>
                <UserContainer key={member[0]}>
                  <h2>{member[1]}</h2>
                </UserContainer>
              </>
            );
          })}
      </Container>
    </>

  );
};

export default UserCreation;
