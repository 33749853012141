import styled from 'styled-components';
import { Form } from 'formik';

export const Forms = styled(Form)`
  padding: 20px;
  background-color: ${({ theme }) => theme.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;

  input {
    margin-bottom: 10px;
  }
`;

export const CheckBoxContainer = styled.div`

  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  /* border: 1px solid ${({ theme }) => theme.lightGrey}; */
  /* padding: 5px 10px;
  border-radius: 8px; */

  background-color: ${({ theme, bgColor }) => {
    switch (bgColor) {
      case 'leader':
        return theme.primaryColor;
      default:
        return theme.white;
    }
  }};


`;