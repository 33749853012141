import React, {useEffect, useState} from 'react';
import api from '../../services/api';
import { Formik, Form } from 'formik';
import { Container, Button, Radio } from '../../components';

import { projectPilars } from '../../assets/projectPilars';

import {  FormContainer, QuestionContainer } from './styles';

const Forms = (props) => {
  const { match } = props;
  const [members, setMembers] = useState([]);
  const [projectMembers, setProjectMembers] = useState([]);
  const [project, setProject] = useState([]);
  const [evaluator, setEvaluator] = useState([]);
  const {date, userid, projectid} = match.params;

  useEffect(() => {
    async function fetchData() {
      const {data} = await api.get('/dev');
      setMembers(data.members);
      const project = data.projects.filter((project)=> project[0] == projectid);
      setProject(JSON.parse(project));
    }
    fetchData();
  }, []);

  useEffect(()=>{
    const member = members.filter((member)=> member[0] == userid);
    setEvaluator(member);
  },[members])

  const initialValue ={}

  const createProject= async (values, resetForm)=>{
    console.log(values)
  }

  return (
    <Container style={{flexDirection: 'column', alignItems: 'center'}}>
      <h1>Olá, {evaluator.length !== 0? evaluator[0][1] : "Avaliador"}</h1>
      <p>Hoje, você fará a avaliação do projeto {project[0][1]}</p>
      <Formik 
          initialValues={initialValue}
          onSubmit={(values, resetForm)=>createProject(values,resetForm)}>
      <Form>
      {projectPilars.map((pilar, pilarIndex)=>{
        return(
          
            <FormContainer>
              <h2>{pilar.title}</h2>
              <p>{pilar.subtitle}</p>
              {pilar.questions.map((question, questionIndex)=>
       
                <QuestionContainer>
                  <p>{question}</p>
                  <div>
                  <Radio
                    name={`${pilarIndex+1}${questionIndex}`}
                    value={'1'}
                    label={1}
                  />
                    <Radio
                      name={`${pilarIndex+1}${questionIndex}`}
                      value={'2'}
                      label={2}
                  />
                  <Radio
                      name={`${pilarIndex+1}${questionIndex}`}
                      value={'3'}
                      label={3}
                  />
                   <Radio
                      name={`${pilarIndex+1}${questionIndex}`}
                      value={'4'}
                      label={4}
                  />
                  </div>
                </QuestionContainer>

              )}
            </FormContainer>

        );
      })}
            <Button type="submit">Avaliar</Button>

      </Form>
      </Formik>

    </Container>
  );
}

export default Forms;