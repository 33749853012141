export const projectPilars = [
  {
    title: "Engajamento",
    subtitle: "Realização das atividades com automotivação, comprometimento, pertencimento e proatividade",
    questions: [
      "Participa ativamente das reuniões dentro dos horários marcados",
      "Demonstra-se proativo diante das questões do projeto"
    ]
  },
  {
    title: "Legado",
    subtitle: "Membros protagonistas com a responsabilidade de impactar e dar continuidade ao desenvolvimento do CEJUR.",
    questions: [
      "Apresenta-se disposto a ajudar a equipe",
      "Assume as responsabilidades do projeto",
      "Trabalha bem em equipe, prezando a comunicação com todos"
    ]
  },
  {
    title: "Espírito Empreendedor",
    subtitle: "Determinação e resiliência para lidar com desafios de maneira inovadora e profissional",
    questions: [
      "Traz ideias inovadoras e sugestões de projetos?",
      "Lida bem com as adversidades ocorridas durante o desenvolvimento do projeto"
    ]
  },
  {
    title: "Excelência",
    subtitle: "A alta qualidade nas atividades realizadas, em constante autossuperação.",
    questions: [
      "Manteve uma boa e constante comunicação com o cliente",
      "Entregou as etapas do projeto com muita qualidade"
    ]
  },
  {
    title: "Propósito",
    subtitle: "Entender o significado e o valor das nossas atividades. ",
    questions: [
      "Apresentou-se motivado e engajado com o projeto",
      "Estimulou e engajou a equipe durante o trabalho"
    ]
  },
  {
    title: "Líder",
    subtitle: "Essa etapa avalia o Líder de seu projeto",
    questions: [
      "Garantiu o suporte técnico e a boa comunicação com o cliente",
      "Contribuiu para um bom andamento do projeto"
    ]
  }
]