import React from 'react';
import { useField } from 'formik';

import { Container, InputContainer, Label } from './styles';

const Input = ({ placeholder, label, ...props }) => {
  const [field, meta, helpers] = useField(props);

  return (
    <Container>
      <Label>{label}</Label>
      <InputContainer placeholder={placeholder} {...field}{...props} />
    </Container>
  );
};

export default Input;
