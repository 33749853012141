import React, { useEffect, useState } from 'react';
import { Button, Container, Input, CheckBox } from '../../components';
import { Formik } from 'formik';

import { Forms, CheckBoxContainer } from './styles';
import api from '../../services/api';

const ProjectCreation = () => {
  const [members, setMembers] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [loading, setLoading] = useState(true);
  const [leader, setLeader] =useState("");

  useEffect(() => {
    async function fetchData() {
      const answer = await api.get('/dev');
      console.log(answer.data.members);
      setMembers(answer.data.members);
      
    }
    fetchData();
  }, []);

  const initialValues={
    projectName:"",
    checked:[]
  }

  const createProject= async (values, resetForm)=>{
    const {projectName, checked} = values;
    setLoading(true);
    
    const answer = await api.post(
      `/dev?projectname=${projectName}&members=${checked}&leader=${leader}&activity=new_project`
      );
    
    setLoading(false);
    if(answer.status === 200){
      resetForm();
      
    }
  }

  return (
    <Container>
      <Formik
      initialValues={initialValues}
      onSubmit={(values,{resetForm})=>createProject(values, resetForm)}>
      <Forms>
        <Input name="projectName"label="Nome do Projeto" placeholder="GVCode" />
        <h2>Escolher membros</h2>
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          {members.map((name, index) => {
            return (
              <CheckBoxContainer bgColor={leader===name[0]? 'leader': null}>
                <CheckBox
                  name="checked"
                  value={`${name[0]}`}
                  label={name[1]}
                />
                {/* <Button onClick={()=>{
                  console.log(name[0])
                  setLeader(name[0])}}>Líder</Button> */}
              </CheckBoxContainer>
            );
          })}
        </div>

        <Button type="submit">Adicionar Projeto</Button>
      </Forms>
      </Formik>
      
    </Container>
  );
};

export default ProjectCreation;
