import React from 'react';
import { Link } from 'react-router-dom';
import api from '../../services/api';

import { ButtonsContainer } from './styles';
import { Button, Container } from '../../components';

const Home = () => {
  return (
    <Container>
      <ButtonsContainer>
        <Link to="/create-project">
          <Button>Criar Projeto</Button>
        </Link>

        <Link to="/create-area">
          <Button>Criar Área</Button>
        </Link>

        <Link to="/add-member">
          <Button>Adicionar Membro</Button>
        </Link>

        <Link to="/see-projects">
          <Button>Ver todos os projetos</Button>
        </Link>
      </ButtonsContainer>
    </Container>
  );
};

export default Home;
