import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  margin: 50px 20px 0 20px;

  a {
    width: 100%;
  }

  button {
    width: 100%;
    margin-bottom: 10px;
  }
`;
