import React from 'react';

import { Container } from './styles';

const Button = ({ children, onClick, to, variant, ...props }) => {
  return (
    <Container onClick={onClick} variant={variant} {...props}>
      {children}
    </Container>
  );
};

export default Button;
