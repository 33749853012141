import styled from 'styled-components';
import { Form } from 'formik';

export const FormContainer = styled.div`
  background-color: ${({theme})=>theme.white};
  margin: 20px;
  border-radius: 8px;
  padding: 20px 10px;
  width: 100%;
  max-width: 500px;
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 20px;
  background-color: ${({theme})=>theme.lightest};
  border-radius: 8px;

  >div{
    display: flex;
    margin-top: 15px;
    div{
      margin-right: 20px;
      
    }
  }
`;


