import React from 'react';
import { Field } from 'formik';

import { Container, InputContainer, Label } from './styles';

const Radio = ({ placeholder, label, ...props }) => {
  return (
    <Container>
      <Field type="radio" placeholder={placeholder} {...props} />
      <Label>{label}</Label>
    </Container>
  );
};

export default Radio;
