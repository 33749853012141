import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
 
 input{
   margin: 0;
   padding: 0;
 }
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.secondaryColor};
  font-size: 1em;
  margin-left: 5px;
  text-transform: capitalize;
`;

