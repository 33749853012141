import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.secondaryColor};
  margin-bottom: 5px;
  font-size: 1em;
`;

export const InputContainer = styled.input`
  background-color: ${({ theme }) => theme.white};
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.lightGrey};
  color: ${({ theme }) => theme.secondaryColor};
  font-size: 1em;

  ::placeholder {
    color: ${({ theme }) => theme.lightGrey};
  }
`;
