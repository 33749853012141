import styled from 'styled-components';

export const Container = styled.button`
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  color: ${({ theme }) => theme.white};
  font-size: 0.9em;
  background-color: ${({ theme, variant }) => {
    switch (variant) {
      case 'default':
        return theme.primaryColor;
      case 'disabled':
        return theme.lightGrey;
      default:
        return theme.primaryColor;
    }
  }};
`;
