import styled from 'styled-components';
import { Form } from 'formik';

export const Forms = styled(Form)`
  padding: 20px;
  background-color: ${({ theme }) => theme.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;

  input {
    margin-bottom: 10px;
  }
  >p{
    color: ${({ theme }) => theme.primaryColor};
    text-align: center;
    margin-top: 10px;
  }
`;

export const UserContainer = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 8px;
  display:flex;
  width:100%;
  max-width: 500px;
  margin-bottom: 10px;
  padding: 20px;
  flex-direction: column;

  h2{
    font-weight: 400;
    font-size: 20px;
  }
  
  p{
    font-size: 12px;
    span{
      text-transform: capitalize;
    }
  }

`;