import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from '../features/Home';
import UserCreation from '../features/UserCreation';
import ProjectCreation from '../features/ProjectCreation';
import AreaCreation from '../features/AreaCreation';
import Forms from '../features/Forms';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/add-member" component={UserCreation} />
      <Route exact path="/create-project" component={ProjectCreation} />
      <Route exact path="/create-area" component={AreaCreation} />
      <Route exact path="/forms/:userid/:date/:projectid" component={Forms} />
    </Switch>
  );
}
